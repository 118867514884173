<template>
  <div class="TicketRefund">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <!-- <span style="height: 40px; line-height: 40px">退款类型：</span>
          <el-select
            v-model="refundType"
            placeholder="请选择"
            style="width: 110px;margin-right:10px"
          >
            <el-option label="正常退费" value="0"></el-option>
            <el-option label="特殊退费" value="1"></el-option>
          </el-select> -->
          <span style="height: 40px; line-height: 40px">退款状态：</span>
          <el-select
            v-model="statusRefund"
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option label="退款成功" value="5"></el-option>
            <el-option label="退款失败" value="6"></el-option>
            <el-option label="退款中" value="4"></el-option>
          </el-select>
          <el-button
            style="height: 40px; margin-left: 10px"
            type="primary"
            plain
            @click="searchSchedule"
            >查询</el-button
          >
          <!-- <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportTicketRefund"
            >导出</el-button
          > -->
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="600"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="赛事名称">
            <template slot-scope="scope">
              <span>{{ scope.row.competitionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="酒店名称">
            <template slot-scope="scope">
              <span>{{ scope.row.hotelName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款金额">
            <template slot-scope="scope">
              <span>{{ scope.row.amount / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="入住人">
            <template slot-scope="scope">
              <span>{{ scope.row.bookUser }}</span>
            </template>
          </el-table-column>
          <el-table-column label="日期列表">
            <template slot-scope="scope">
              <span>{{ scope.row.days }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款状态">
            <template slot-scope="scope">
              <span>{{
                scope.row.refundStatus == 5
                  ? "退款成功"
                  : scope.row.refundStatus == 6
                  ? "退款失败"
                  : scope.row.refundStatus == 4
                  ? "退款中"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款时间">
            <template slot-scope="scope">
              <span>{{ scope.row.refundTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请原因">
            <template slot-scope="scope">
              <span>{{ scope.row.applyReason }}</span>
            </template>
          </el-table-column>
          <el-table-column label="其他原因">
            <template slot-scope="scope">
              <span>{{ scope.row.otherReason }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formModel: {},
      applyReason: "",
      RefundId: "",
      statusRefund: "",
      refundType: "",
      raceName: "",
      playerName: "",
      startTime: "",
      endTime: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
    };
  },
  methods: {
    dialogClose() {
      this.applyReason = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.findRefundInfo();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.findRefundInfo();
      console.log(`当前页: ${val}`);
    },
    //获取查询所有退款信息
    async findRefundInfo() {
      try {
        const result = await this.$api.findRefundHotelInfo(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_EQ_refundStatus=${this.statusRefund}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item.reason) {
            item.reason = JSON.parse(item.reason).join(",");
          }
          if (item.bookUser) {
            item.bookUser = JSON.parse(item.bookUser).join(",");
          }
          if (item.days) {
            item.days = JSON.parse(item.days).join(",");
          }
          if (item.applyReason) {
            item.applyReason = JSON.parse(item.applyReason).join(",");
          }
        });
        // this.tableData.forEach((item) => {
        //   item.bookUser = JSON.parse(item.bookUser).join(",");
        // });
        this.totalCount = result.totalCount;
        console.log(this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    //查询所有退款信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.findRefundHotelInfo(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_EQ_refundStatus=${this.statusRefund}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item.reason) {
            item.reason = JSON.parse(item.reason).join(",");
          }
          if (item.bookUser) {
            item.bookUser = JSON.parse(item.bookUser).join(",");
          }
          if (item.days) {
            item.days = JSON.parse(item.days).join(",");
          }
          if (item.applyReason) {
            item.applyReason = JSON.parse(item.applyReason).join(",");
          }
        });
        // this.tableData.forEach((item) => {
        //   item.bookUser = JSON.parse(item.bookUser).join(",");
        // });
        this.totalCount = result.totalCount;
        console.log(this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    checkRefund(index, row) {
      console.log(row);
      this.RefundId = row.id;
      this.formModel = JSON.parse(JSON.stringify(row));
      console.log(this.formModel);
      this.dialogVisible = true;
    },
    // async confirm(status) {
    //   if (status == 1) {
    //     this.dialogVisible = false;
    //     try {
    //       const result = await this.$api.checkRefund(
    //         `id=${this.RefundId}&refundStatus=${status}`
    //       );
    //       console.log(result);
    //       this.findRefundInfo();
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   } else if (status == 2) {
    //     if (this.applyReason == "") {
    //       this.$message({
    //         type: "warning",
    //         message: "请输入不通过的理由",
    //       });
    //     } else {
    //       this.dialogVisible = false;
    //       try {
    //         const result = await this.$api.checkRefund(
    //           `id=${this.RefundId}&auditStatus=${status}&reason=${this.applyReason}`
    //         );
    //         console.log(result);
    //         this.findRefundInfo();
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     }
    //   }
    // },
    async exportTicketRefund() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportTicketRefund(
          `search_EQ_refundStatus=${this.statusRefund}&search_LIKE_competitionName=${this.raceName}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.findRefundInfo();
  },
};
</script>

<style lang="less">
.TicketRefund {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 150px;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
